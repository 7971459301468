import React from 'react';
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Wrapper } from 'Common/styled'
import Loader from 'Components/Loader'
import { AG_GRID_LOCALE_IT } from 'Common/ag-grid.locale'
import AgTableProductImageRenderer from 'Components/AgTable/renderers/AgTableProductImageRenderer'
import AgTableNoteRenderer from './renderers/AgTableNoteRenderer'
import moment from 'moment'
import * as _ from 'lodash'
import {num} from 'Common/utils';
import AgTableSelectEditor from './renderers/AgTableSelectEditor'
import AgTableInputEditor from './renderers/AgTableInputEditor'
import AgTableListTooltipRenderer from './renderers/AgTableListTooltipRenderer'
import AgTableInfoRenderer from './renderers/AgTableInfoRenderer'
import AgTableInfoTooltip from './renderers/AgTableInfoTooltip'
import AgTableTagEditor from 'Components/AgTable/renderers/AgTableTagEditor'
import AgTableWarningRenderer from './renderers/AgTableWarningRenderer';
import AgTableErrorAndWarningPanel from './panels/AgTableErrorAndWarningPanel';
import AgTablePopupListEditor from 'Components/AgTable/renderers/AgTablePopupListEditor'
import AgTableModalRenderer from 'Components/AgTable/renderers/AgTableModalRenderer'
import AgTableInfoFilePicker from 'Components/AgTable/renderers/AgTableInfoFilePicker';
import AgTableNotesFilter from './filters/AgTableNotesFilter';
import AgTableFloatingNotesFilter from './filters/AgTableFloatingNotesFilter';
import { Pagination } from 'antd'

class AgTable extends React.Component {
  state = {
    gridOptions: null,
    gridApi: null,
    gridColumnApi: null,
    changedRowIds: [],
    data: [],
    originalData: [],
    currentPage: 1,
    currentPageSize: 50,
    totalRowCount: 0
  }

  filterParamsGeneric = {
    buttons: ['apply', 'reset']
  }

  filterParamsNumber = {
    filterOptions: [
      'equals',
      'notEqual',
      'lessThan',
      'lessThanOrEqual',
      'greaterThan',
      'greaterThanOrEqual',
      {
        displayKey: 'equalsNull',
        displayName: 'Vuoto',
        test: (filterValue, cellValue) => {
          return cellValue === null;
        },
        hideFilterInput: true
      },
      'empty'
    ]
  }

  filterParamsBool = {
    valueFormatter: params => {
      const value = String(params.value);
      return value === 'true' ? 'Si' : 'No';
    }
  };

  filterParamsBoolSC = {
    valueFormatter: params => {
      const value = String(params.value);
      return value === 'true' ? 'Simbolo' : 'Complementare';
    }
  };

  filterParamsDate = {
    filterOptions: [
      'equals',
      'notEqual',
      'lessThan',
      'greaterThan',
      'inRange',
      {
        displayKey: 'equalsNull',
        displayName: 'Vuoto',
        test: (filterValue, cellValue) => {
          return cellValue === null;
        },
        hideFilterInput: true
      },
      'empty'
    ]
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.initGridOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.gridApi !== this.state.gridApi && this.props.emitChange) {
      this.props.emitChange({
        action: 'gridLoaded',
        gridApi: this.state.gridApi,
        gridColumnApi: this.state.gridColumnApi
      });

      if (this.props.loadServerSideData) {
        this.state.gridApi.showLoadingOverlay();

        this.props.loadServerSideData(this.state.currentPage, this.state.currentPageSize)
          .then(params => {
            this.state.gridApi.hideOverlay();
            this.loadData(params.data);
            this.setState({ totalRowCount: params.count });
          })
          .catch(console.error);
      }
    }
  }

  loadData(data) {
    this.setState({ data });
    this.state.gridApi.setRowData([...data]);
  }

  cleanChangedRowIds(changedRowIds = []) {
    this.setState({
      changedRowIds: this.state.changedRowIds.filter(changedRowId => changedRowIds.indexOf(changedRowId) === -1)
    });
  }

  onPaginationPageChange = (page, pageSize) => {

    this.setState({
      currentPage: page,
      currentPageSize: pageSize
    });

    this.state.gridApi.showLoadingOverlay();

    this.props.loadServerSideData(page, pageSize)
      .then(params => {
        this.state.gridApi.hideOverlay();
        this.loadData(params.data);
        setTimeout(() => this.setState({ totalRowCount: params.count }));
      })
      .catch(console.error);
  }

  initGridOptions() {
    const { columns, canMakeSelection, pagination, serverSide } = this.props;

    this.setState({
      gridOptions: {
        localeText: AG_GRID_LOCALE_IT,
        columnDefs: [
          {
            checkboxSelection: true,
            width: 50,
            lockPosition: true,
            lockVisible: true,
            sortable: false,
            editable: false,
            resizable: false,
            suppressMenu: true,
            suppressMovable: true,
            floatingFilter: false,
            hide: canMakeSelection !== true
          },
          {
            headerName: '',
            width: 50,
            field: 'errors',
            lockPosition: true,
            lockVisible: true,
            sortable: false,
            editable: false,
            resizable: false,
            suppressMenu: true,
            suppressMovable: true,
            floatingFilter: false,
            cellStyle: {
              textAlign: 'center'
            },
            cellRenderer: 'infoRenderer'
          },
          {
            headerName: '',
            width: 50,
            valueGetter: params => (this.props.getIncompleteProductWarnings && this.props.getIncompleteProductWarnings(params.data)) || [],
            lockPosition: true,
            lockVisible: true,
            sortable: false,
            editable: false,
            resizable: false,
            suppressMenu: true,
            suppressMovable: true,
            floatingFilter: false,
            cellStyle: {
              textAlign: 'center'
            },
            cellRenderer: 'warningRenderer'
          },
          ...this.readColumns(columns)
        ],
        rowData: [],
        getRowClass: (params) => {
          const classes = [];

          if (params.data && params.data.errors.length > 0) {
            classes.push('lm-error');
          }

          return classes;
        },
        getRowStyle: params => {
          let style = {};

          if (this.props.getRowStyle) {
            style = {
              ...style,
              ...this.props.getRowStyle(params)
            };
          }

          return style;
        },
        rowClassRules: {
          'lm-warning': (params) => (this.props.getIncompleteProductWarnings && this.props.getIncompleteProductWarnings(params.data).length > 0) || false
        },
        tooltipShowDelay: 0,
        headerHeight: 50,
        pagination: false,
        onGridReady: this.onGridReady.bind(this),
        defaultColDef: {
          width: 150,

          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          suppressKeyboardEvent: params => {
            if (params.editing && params.colDef.cellEditor === 'tagEditor') {
              return true;
            }

            return false;
          }
        },
        components: {
          productImage: AgTableProductImageRenderer,
          listTooltip: AgTableListTooltipRenderer,
          noteRenderer: AgTableNoteRenderer,
          modalRenderer: AgTableModalRenderer,
          selectEditor: AgTableSelectEditor,
          inputEditor: AgTableInputEditor,
          infoRenderer: AgTableInfoRenderer,
          infoTooltip: AgTableInfoTooltip,
          tagEditor: AgTableTagEditor,
          popupListEditor: AgTablePopupListEditor,
          warningRenderer: AgTableWarningRenderer,
          errorAndWarningPanel: AgTableErrorAndWarningPanel,
          fileRenderer: AgTableInfoFilePicker,
          notesFilter: AgTableNotesFilter,
          floatingNotesFilter: AgTableFloatingNotesFilter
        },
        columnTypes: {
          'moneyColumn': {
            valueFormatter: (params) => {
              return _.isNil(params.value) ? null : num(params.value).format('$0,0.00');
            },
            filterParams: {
              ...this.filterParamsGeneric,
              ...this.filterParamsNumber
            }
          },
          'dateColumn': {
            valueSetter: (params) => {
              if (!params.newValue) {
                return false;
              }

              let date = moment(params.newValue, 'DD/MM/YYYY');

              if (date.isValid()) {
                params.data[params.colDef.field] = date.toDate().toISOString();
                return true;
              }

              date = moment(params.newValue);

              if (date.isValid()) {
                params.data[params.colDef.field] = date.toDate().toISOString();
                return true;
              }

              return false;
            },
            valueFormatter: (params) => {
              return params.value ? moment(params.value).format(_.get(params.colDef, 'format', 'DD/MM/YYYY')) : '';
            },
            filterParams: {
              ...this.filterParamsGeneric,
              ...this.filterParamsDate
            }
          },
          'boolscColumn': {
            valueFormatter: (params) => {
              return params.value === true ? 'Simbolo' : 'Complementare';
            },
            filterParams: {
              ...this.filterParamsGeneric,
              ...this.filterParamsBoolSC
            }
          },
          'boolColumn': {
            valueFormatter: (params) => {
              return params.value === true ? 'Si' : 'No';
            },
            filterParams: {
              ...this.filterParamsGeneric,
              ...this.filterParamsBool
            }
          },
          'booleanColumn': {
            valueFormatter: (params) => {
              return params.value === true ? 'Si' : 'No';
            },
            filterParams: {
              ...this.filterParamsGeneric,
              ...this.filterParamsBool
            }
          },
          'decimalColumn': {
            valueFormatter: (params) => {
              return params.value !== null && params.value !== undefined ? parseFloat(params.value).toFixed(params.column.colDef.cellEditorParams.precision ? params.column.colDef.cellEditorParams.precision : 2).replace('.', ',') : '';
            },
            filterParams: {
              ...this.filterParamsGeneric,
              ...this.filterParamsNumber
            }
          },
          'integerColumn': {
            valueFormatter: params => {
              return params.value !== null && params.value !== undefined ? !isNaN(Number(params.value)) ? params.value + '' : '' : '';
            },
            filterParams: {
              ...this.filterParamsGeneric,
              ...this.filterParamsNumber
            }
          }
        },
        rowSelection: 'multiple',
        getContextMenuItems: this.getContextMenuItems.bind(this),
        enableRangeSelection: true,
        suppressRowClickSelection: true,
        onRowDataChanged: ev => {
          const originalData = [];

          ev.api.forEachNode(node => {
            originalData.push(JSON.parse(JSON.stringify(node.data)));
          });

          this.setState({
            originalData: JSON.parse(JSON.stringify(originalData))
          });
        },
        onColumnVisible: ev => {
          if (typeof this.props.emitChange === 'function') {
            this.props.emitChange({
              action: 'columnVisibilityChange',
              column: ev.column
            });
          }
        },
        onCellValueChanged: ev => {
          const originalRow = this.state.originalData.find(o => o.id === ev.data.id);

          // if (originalRow && originalRow[ev.colDef.field] === ev.newValue) {
          //   return;
          // }

          let invalidFormat = false;

          switch (ev.colDef.type) {
            case 'moneyColumn':
            case 'decimalColumn':
              if (isNaN(Number(ev.newValue))) {
                invalidFormat = true;
              }

              break;

            case 'integerColumn':
              if (isNaN(Number(ev.newValue)) || !Number.isInteger(Number(ev.newValue))) {
                invalidFormat = true;
              }

              break;

            case 'dateColumn':
              break;

            case 'boolColumn':
            case 'booleanColumn':
            case 'boolscColumn':
              if (typeof ev.newValue !== 'boolean') {
                invalidFormat = true;
              }

              break;

            default:
          }

          if (invalidFormat) {
            ev.node.setDataValue(ev.colDef.field, ev.oldValue);
            return;
          }

          if (ev.colDef.cellEditorParams && ev.colDef.cellEditorParams.minValue && typeof ev.colDef.cellEditorParams.minValue === 'function' && ev.newValue !== null && ev.colDef.cellEditorParams.minValue(ev.data, ev.newValue, ev.oldValue) > ev.newValue) {
            ev.node.setDataValue(ev.colDef.field, ev.oldValue);
            return;
          }

          if (ev.colDef.cellEditorParams && ev.colDef.cellEditorParams.maxValue && typeof ev.colDef.cellEditorParams.maxValue === 'function' && ev.newValue !== null && ev.colDef.cellEditorParams.maxValue(ev.data, ev.newValue, ev.oldValue) && ev.colDef.cellEditorParams.maxValue(ev.data, ev.newValue, ev.oldValue) < ev.newValue) {
            ev.node.setDataValue(ev.colDef.field, ev.oldValue);
            return;
          }

          const changedRowIds = [...this.state.changedRowIds];

          if (changedRowIds.indexOf(ev.node.id) === -1) {
            changedRowIds.push(ev.node.id);

            this.setState({
              changedRowIds
            });
          }
          
          if (typeof this.props.emitChange === 'function') {
            this.props.emitChange({
              action: 'cellChange',
              node: ev.node,
              colDef: ev.colDef,
              oldValue: ev.oldValue,
              newValue: ev.newValue
            });
          }
        },
        onSelectionChanged: ev => {
          this.props.emitChange({
            action: 'selectionChange',
            nodes: ev.api.getSelectedNodes()
          });
        },
        statusBar: {
          statusPanels: [
            // {
            //   statusPanel: 'errorAndWarningPanel',
            //   statusPanelParams: { getIncompleteProductWarnings: this.props.getIncompleteProductWarnings},
            //   align: 'left',
            // },
            {
              statusPanel: 'agTotalAndFilteredRowCountComponent',
              align: 'left',
            },
            {
              statusPanel: 'agTotalRowCountComponent',
              align: 'center',
            },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' },
          ]
        },
        processCellFromClipboard: (params) => {
          let value = params.value.trim()

          // Enum types
          if (params.column.colDef.cellEditor === 'selectEditor') {
            const match = params.column.colDef.cellEditorParams.selectList.find(e => {
              switch (typeof e.id) {
                case 'string':
                  return e.id.toLowerCase() === value.toLowerCase()
                case 'number':
                  return e.id === Number(value)
                default:
                  return false
              }
            })

            return match ? match.id : null
          }

          // Other types
          switch (params.column.colDef.type) {
            case 'integerColumn':
              value = value.replace(/\./g, '').replace(',', '.')
              return isNaN(value) ? null : Number(value).toFixed(0)
            case 'decimalColumn':
              value = value.replace(/\./g, '').replace(',', '.')
              return isNaN(value) ? null : Number(value).toFixed(params.column.colDef.cellEditorParams.precision ? params.column.colDef.cellEditorParams.precision : 2)
            default:
              return value
          }
        }
      }
    });
  }

  getContextMenuItems() {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'csvExport',
      // 'resetColumns',
      'autoSizeAll',
      {
        name: 'Risetta filtri',
        disabled: false,
        action: () => {
          const { gridApi } = this.state;
          gridApi.setFilterModel(null);
        }
      }
      // {
      //   name: 'Genera Serie',
      //   disabled: false,
      //   action: () => {
      //     const { gridApi } = this.state;
      //     const cellRanges = gridApi.getCellRanges();
      //     const { columns, startRow, endRow } = cellRanges[0];
      //     const colIds = columns.map(column => column.colId);
      //     const initialValues = {};
      //
      //     const rowNode = gridApi.getDisplayedRowAtIndex(startRow.rowIndex);
      //
      //     colIds.forEach(colId => {
      //       const value = Number(rowNode.data[colId]);
      //
      //       if (!isNaN(value)) {
      //         initialValues[colId] = value;
      //       }
      //     });
      //
      //     for (let a = startRow.rowIndex + 1; a <= endRow.rowIndex; a++) {
      //       const rowNode = gridApi.getDisplayedRowAtIndex(a);
      //       const data = rowNode.data;
      //
      //       colIds.forEach(colId => {
      //         if (initialValues.hasOwnProperty(colId)) {
      //           initialValues[colId] += 1;
      //           data[colId] = initialValues[colId];
      //         }
      //       });
      //     }
      //
      //     this.gridApi.refreshCells();
      //   }
      // }
    ];
  }

  readColumns(columns) {
    return columns.filter(column => column.visible === undefined || column.visible === true).map(column => {
      const title = column.cleanTitle || column.title;
      const width = column.width || (title.length > 20 ? 200 : 150);

      const extras = {
        cellStyle: {
          'white-space': 'normal',
          'line-height': 'normal',
          'display': 'flex',
          'flex-direction': 'column',
          'justify-content': 'center'
        }
      };

      if(column.contentType === 'files') {
        extras.cellRenderer = 'fileRenderer'
        extras.cellStyle.textAlign = 'center';
        extras.filter = null;
      }

      if (column.contentType === 'note') {
        extras.cellRenderer = 'noteRenderer';
        extras.cellStyle.textAlign = 'center';
        extras.filter = 'notesFilter'
        extras.floatingFilterComponent = 'floatingNotesFilter'
      } else if (column.contentType) {
        extras.type = `${column.contentType}Column`;
        extras.cellStyle.textAlign = 'center';
      }

      if (column.contentType === 'date') {
        extras.filter = 'agDateColumnFilter';
      } else if (['number', 'money'].indexOf(column.contentType) > -1) {
        extras.filter = 'agNumberColumnFilter';
      }

      if (column.renderer) {
        extras.cellRenderer = column.renderer;
      }

      if (column.alignCenter) {
        extras.cellStyle.textAlign = 'center';
      }

      if (column.alignRight) {
        extras.cellStyle.textAlign = 'right';
      }

      extras.cellEditorParams = {};

      if (column.contentType) {
        switch (column.contentType) {
          case 'integer':
            extras.cellEditorParams.minValue = column.minValue;
            extras.cellEditorParams.maxValue = column.maxValue;
            extras.cellEditor = 'inputEditor';
            break;

          case 'decimal':
            extras.cellEditorParams.precision = column.precision;
            extras.cellEditorParams.minValue = column.minValue;
            extras.cellEditorParams.maxValue = column.maxValue;
            extras.cellEditor = 'inputEditor';
            break;

          case 'date':
            extras.cellEditorParams.format = column.format;
            extras.cellEditorParams.disabledDate = column.disabledDate;
            extras.cellEditor = 'inputEditor';
            break;
          case 'boolSC':
            extras.cellEditor = 'inputEditor';
            extras.cellStyle.textAlign = 'center';
          case 'bool':
          case 'boolean':
            extras.cellEditor = 'inputEditor';
            extras.cellStyle.textAlign = 'center';
            break;

          default:
            extras.cellEditor = 'inputEditor';
        }
      } else {
        extras.cellEditor = 'inputEditor';
      }

      if (column.cellEditor) {
        extras.cellEditor = column.cellEditor;
      }

      if (column.cellRendererParams) {
        extras.cellRendererParams = column.cellRendererParams;
      }

      if (column.editableSelect === true) {
        extras.cellEditor = 'selectEditor';
        extras.cellEditorParams = {
          ...extras.cellEditorParams,
          selectList: column.editableSelectList,
          opts: column.editableSelectOpts || {},
          maxSelects: column.maxSelectedOpts || null
        };
      }

      if (column.render) {
        extras.valueGetter = (params) => {
          return column.render(params.data[params.colDef.field], params.data);
        };
      }

      if (column.valueFormatter) {
        extras.valueFormatter = column.valueFormatter;
        extras.filterParams = {
          valueFormatter: column.valueFormatter
        }
      }

      if (column.valueGetter) {
        extras.valueGetter = column.valueGetter;
      }

      if (column.valueParser) {
        extras.valueParser = column.valueParser;
      }

      if (column.cellEditorParams) {
        extras.cellEditorParams = {
          ...extras.cellEditorParams,
          ...column.cellEditorParams
        };
      }

      const translator = {
      };

      return {
        headerName: title,
        field: column.dataIndex,
        sort: column.sort || null,
        width: width + 50,
        hide: column.hidden === true,
        cellClassRules: {
          'lm-editable': params => {
            if (params.colDef.cellRenderer && (['noteRenderer'].indexOf(params.colDef.cellRenderer) > -1  )) {
              return false;
            }
            if (typeof params.colDef.editable === 'function') {
              return params.colDef.editable(params) === true;
            }

            return params.colDef.editable === true;
          },
          'lm-error': params => {
            const errors = params.data.errors || [];

            for (const error of errors) {
              if (error.fields.indexOf(params.colDef.field) > -1) {
                return true;
              }
            }

            return false;
          },
          'lm-warning': params => {
            const warnings = (this.props.getIncompleteProductWarnings && this.props.getIncompleteProductWarnings(params.data)) || [];

            for (const warning of warnings) {
              if (warning.fields.indexOf(params.colDef.field) > -1) {
                return true;
              }
            }

            return false;
          }
        },
        editable: typeof column.editable === 'function' ? (params) => column.editable(params.data[params.colDef.field], params.data, params) : column.editable,
        ...extras
      };
    });
  }

  onGridReady(ev) {
    this.setState({
      gridApi: ev.api,
      gridColumnApi: ev.columnApi
    });
  }

  rowDataChanged(ev) {
    this.props.emitChange({
      action: 'rowDataChanged',
      ...ev
    });
  }

  render() {
    const { gridOptions, totalRowCount } = this.state;
    const { serverSide, serverSidePagination } = this.props;

    if (gridOptions === null) {
      return <Loader />;
    }

    return (
      <Wrapper style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', }}>
        <div
          className="ag-theme-alpine"
          style={{
            width: '100%',
            flexGrow: 1 }}
        >
          <AgGridReact gridOptions={gridOptions} />
          {serverSidePagination && <Pagination style={{ marginTop: 25, marginLeft: 'auto' }} defaultCurrent={1} defaultPageSize={50} total={totalRowCount} onChange={this.onPaginationPageChange} />}
        </div>
      </Wrapper>
    )
  }
}

export default AgTable;

function getSelectEditor() {
  function SelectEditor() {}
  SelectEditor.prototype.init = function (params) {

  }
}
