import React from 'react';
import { Wrapper } from 'Common/styled'
import { ROLE_AZ_COMM, ROLE_CP, ROLE_CPXS, ROLE_CS, ROLE_MERCH } from 'Common/constants/global'
import ModalConfirm from 'Common/ModalConfirm'
import api from 'Common/api'
import { Badge, Button, Col, Popconfirm, Radio, Row } from 'antd'
import { ActionBar } from 'Components/DossierReferencesTable/styled'
import AppAccess from 'Common/appAccess'
import AgTable from 'Components/AgTable/AgTable'
import * as _ from 'lodash'
import { feedbackNotification, handleServerError, handleServerSuccess } from 'Common/utils'
import * as moment from 'moment';
import {
  DATA_COMPLETAMENTO_GTE_DATA_INIZIO,
  DATA_COMPLETAMENTO_NON_VALIDA, DATA_FINE_EVENTO_NON_VALIDA,
  DATA_INIZIO_EVENTO_NON_VALIDA, STOCK_LT_STOCK_SEDE
} from 'Common/constants/product-errors'

const IMPLEMENTATION_INTERVAL = 37;

class ProdottiSimboloTable extends React.Component {
  state = {
    canSave: false,
    originalRecords: {},
    changedRecords: [],
    recordChanges: {},
    selectedNodes: [],
    fullscreen: false,
    columns: [
      {
        title: 'IMMAGINE',
        dataIndex: 'foto',
        align: 'center',
        width: 100,
        renderer: 'productImage'
      },
      {
        title: 'REF LM',
        dataIndex: 'idReferenza',
        key: 'idReferenza',
        searchable: true,
        searchMultiple: true,
        sortable: true,
        align: 'center',
        width: 150
      },
      {
        title: 'GAMMA',
        dataIndex: 'gamma',
        key: 'gamma',
        searchable: true,
        sortable: true,
        align: 'center',
        width: 250
 },
      {
        title: 'DESCRIZIONE',
        dataIndex: 'descrizione',
        key: 'descrizione',
        searchable: true,
        sortable: true,
        align: 'center',
        width: 250
      },
      {
        title: 'SIMBOLO/COMPLEMENTARE',
        dataIndex: 'simbolo',
        key: 'simbolo',
        align: 'center',
        contentType: 'boolsc',
        width: 100,
        valueGetter: params => {
          return params.data.simbolo && params.data.simbolo.toString() === 'true';
        },
        valueFormatter: params => {
          return params.value && params.value.toString() === 'true' ? 'Simbolo' : 'Complementare';
        }
      },
      {
        title: 'SPT',
        dataIndex: 'stock',
        contentType: 'decimal',
        precision: 2,
        align: 'center',
        width: 100,
        minValue: record => {
          const { dossier } = this.props;

          if (dossier.idStore === 0 || (record.nomeReparto && record.nomeReparto.startsWith('01'))) {
            return undefined;
          }

          return record.stockSede || undefined;
        },
        editable: (value, record, params) => {
          const {dossier, user} = this.props;

          const today = moment().startOf('day');
          return (dossier.idStore === 0 && [ROLE_AZ_COMM, ROLE_CPXS].includes(user.role)) ||
            (dossier.idStore > 0 &&
              dossier.idStore === user.store && user.role === ROLE_CS &&
              ((moment(record.dataInizioEvento).startOf('day').isSameOrBefore(today) && (record.dataFineEvento === null || moment(record.dataFineEvento).startOf('day').isAfter(today))) || moment(record.dataCompletamentoNegozio).startOf('day').isSameOrAfter(today)));
        }
      },
      {
        title: 'DATA AVS',
        dataIndex: 'dataAVS',
        align: 'center',
        contentType: 'date',
        width: 150,
        // editable: (value, record, params) => {
        //    const {dossier, user} = this.props;
        //    return dossier.idStore === 0 && user.role === ROLE_AZ_COMM;
        // },
        disabledDate: (current, params) => {
          const { dossier } = this.props;
          const dataInizioEvento = moment(params.data.dataInizioEvento);
           return current <= moment().add(dossier.changeLimit, 'days') ||   (dataInizioEvento.isValid() && current >= dataInizioEvento);
         }
      },
      {
        title: 'DATA INIZIO EVENTO',
        dataIndex: 'dataInizioEvento',
        align: 'center',
        contentType: 'date',
        width: 150,
        valueSetter: (params) => {
          console.log(params);
          const newValue = moment(params.newValue).startOf('day');
          const newDataCompletamentoNegozio = newValue.subtract(IMPLEMENTATION_INTERVAL, 'days');
          params.node.setDataValue('dataCompletamentoNegozio', newDataCompletamentoNegozio.toDate().toISOString());
          return newValue;
        },
        editable: (value, record, params) => {
          const {dossier, user} = this.props;

          return dossier.idStore === 0 && [ROLE_AZ_COMM, ROLE_CPXS].includes(user.role);
        },
        disabledDate: (current, params) => {
          const { dossier } = this.props;
          const validDate = moment().startOf('day').add(IMPLEMENTATION_INTERVAL + dossier.changeLimit, 'days');
          return moment(current).startOf('day').isBefore(validDate);
        },
      },
      {
        title: 'DATA FINE EVENTO',
        dataIndex: 'dataFineEvento',
        align: 'center',
        contentType: 'date',
        width: 150,
        editable: (value, record, params) => {
          const {dossier, user} = this.props;

          return dossier.idStore === 0 && [ROLE_AZ_COMM, ROLE_CPXS].includes(user.role);
        },
        disabledDate: (current, params) => {
          return moment(current).startOf('day').isSameOrBefore(moment(params.data.dataInizioEvento).startOf('day'));
        }
      },
      {
        title: 'DATA COMPLETAMENTO NEGOZIO',
        dataIndex: 'dataCompletamentoNegozio',
        align: 'center',
        contentType: 'date',
        width: 150
      },
      {
        title: 'LISTA PRODOTTI SIMBOLO DI RIFERIMENTO',
        dataIndex: 'rifProdottiSimbolo',
        align: 'center',
        width: 285,
        editable: (value, record, params) => {
          const { user } = this.props;
          return [ROLE_AZ_COMM, ROLE_CPXS].includes(user.role) && record.simbolo !== true;
        },
        valueFormatter: params => {
          if (Array.isArray(params.value)) {
            return params.value.join(', ');
          }

          return params.value;
        },
        valueSetter: params => {
          console.log(params);
          return true;
        },
        renderer: 'listTooltip',
        cellEditor: 'popupListEditor',
        cellEditorParams: {
          maxElements: 3,
          validateValue: value => {
            return /^[0-9]{0,8}$/.test(value);
          }
        }
      },
      {
        title: 'LISTA NEGOZI',
        dataIndex: 'rifNegozi',
        align: 'center',
        width: 285,
        editable: () => {
          const { user } = this.props;
          return [ROLE_AZ_COMM, ROLE_CPXS].includes(user.role);
        },
        valueFormatter: params => {
          const { stores } = this.props;
          if (Array.isArray(params.value)) {
            if (params.colDef.cellEditorParams.selectList.length === params.value.length) {
              return 'Tutti';
            }

            return stores
              .filter(store => params.value.includes(store.id))
              .map(store => `${store.id}-${store.name}`)
              .join(', ');
          }
          return '';
        },
        visible: this.props.dossier.idStore === 0,
        editableSelect: true,
        editableSelectList: this.props.stores.map(store => ({id: store.id, label: `${store.id}-${store.name}`})),
        editableSelectOpts: {mode: 'multiple', allowClear: true, maxTagCount: 1, maxTagTextLength: 15},
        renderer: 'listTooltip'
      },
      {
        title: 'REPARTO',
        dataIndex: 'nomeReparto',
        key: 'nomeReparto',
        align: 'center',
        searchable: true,
        sortable: true,
        width: 200
      },
      {
        title: 'SOTTOREPARTO',
        dataIndex: 'nomeSottoreparto',
        key: 'nomeSottoreparto',
        align: 'center',
        searchable: true,
        sortable: true,
        width: 200
      },
      {
        title: 'TIPO',
        dataIndex: 'nomeTipo',
        key: 'nomeTipo',
        align: 'center',
        searchable: true,
        sortable: true,
        width: 200
      },
      {
        title: 'SOTTOTIPO',
        dataIndex: 'nomeSottotipo',
        key: 'nomeSottotipo',
        align: 'center',
        searchable: true,
        sortable: true,
        width: 200
      },
      {
        title: 'DATA ULTIMA MODIFICA',
        dataIndex: 'lastUpdate',
        align: 'center',
        valueFormatter: params => {
          return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
        },
        width: 150
      },
    ]
  }

  constructor(props, context) {
    super(props, context)
    this.agTable = React.createRef();
  }

  componentDidMount() {
    const {columns} = this.state;

    const visibleColumns = columns.filter(column => column.hidden !== true).map(column => column.dataIndex);
    this.setState({visibleColumns: visibleColumns});

    window.onbeforeunload = () => {
      const { changedRecords } = this.state;

      if (changedRecords.length > 0) {
        return true;
      }
    }
  }

  deleteRecords = () => {
    const { dossier } = this.props;
    const {selectedNodes} = this.state;

    this.agTable.current.state.gridApi.showLoadingOverlay();

    api.dossiers.deleteRefs(dossier.id, 'checked', {}, selectedNodes.map(node => node.data.idReferenza)).then(() => {
      this.agTable.current.state.gridApi.applyTransaction({
        remove: [...selectedNodes.map(node => node.data)]
      });
      this.agTable.current.state.gridApi.hideOverlay();
      handleServerSuccess();
    }, handleServerError);
  };

  getAllProducts() {
    this.agTable.current.state.gridApi.showLoadingOverlay();
    const {dossier} = this.props;

    if (_.isNil(dossier)) {
      return;
    }

    api.dossiers.getAllProducts(dossier.id).then((response) => {
      this.setState({
        originalRecords: response.data.reduce((acc, curr) => ({
          ...acc,
          ['id:' + curr.id]: {...curr},
          ['idReferenza:' + curr.idReferenza]: {...curr}
        }), {})
      });
      this.agTable.current.loadData(response.data);
      this.agTable.current.state.gridApi.hideOverlay();
    }).catch();
  }

  onAgTableChange = (change) => {
    const { onTableChanges } = this.props;
    switch (change.action) {
      case 'cellChange':
        const recordChanges = {
          ...this.state.recordChanges,
          [change.node.id]: change.node
        };

        if (change.colDef && change.colDef.field !== 'errors') {
          this.validateProduct(change);
        }

        if (change.colDef && change.colDef.field === 'dataInizioEvento') {
          const newDate = moment(change.newValue).startOf('day');
          const newDataCompletamentoNegozio = newDate.subtract(IMPLEMENTATION_INTERVAL, 'days');
          change.node.setDataValue('dataCompletamentoNegozio', newDataCompletamentoNegozio).toDate().toISOString();
        }

        const canSave = Object.keys(recordChanges).length > 0 && Object.keys(recordChanges).filter(key => recordChanges[key].data.errors.length > 0).length === 0;

        if (typeof onTableChanges === 'function') {
          onTableChanges({...recordChanges});
        }

        this.setState({
          recordChanges: recordChanges,
          canSave
        });

        break;

      case 'selectionChange':
        this.setState({
          selectedNodes: [...change.nodes]
        });

        break;

      case 'gridLoaded':
        this.getAllProducts();
        break;

      default:
    }
  };

  saveRecords = () => {
    const {recordChanges, changedRecords} = this.state
    const {dossier} = this.props

    this.agTable.current.state.gridApi.showLoadingOverlay();

    api.dossiers.saveProdottiSimbolo(dossier.id, Object.keys(recordChanges).map(key => recordChanges[key].data)).then(response => {
      const productsWithErrors = response.data.filter(record => record.errors.length > 0).length;
      const changedProductsWithoutErrors = response.data.filter(record => record.errors.length === 0);
      const changedProductRefs = changedProductsWithoutErrors.map(record => record.idReferenza);
      let updatedChangedRecords = changedRecords.filter(changedRecord => {
        return changedProductRefs.indexOf(changedRecord.idReferenza) === -1
      });
      updatedChangedRecords = [...updatedChangedRecords, ...changedProductsWithoutErrors];

      if (productsWithErrors === 0) {
        handleServerSuccess();
      } else {
        let message = `Ci sono ${productsWithErrors} referenze non salvate a causa di errori`;
        if (productsWithErrors === 1) {
          message = `C'è una referenza non salvata a causa di errori`;
        }

        feedbackNotification('warning', `Operazione parziale`, message);
      }

      const updates = [];
      const updatedRecords = response.data;
      const changedRowIds = [];
      const updatedRecordChanges = {...recordChanges};

      for (const recordChangeKey in recordChanges) {
        if (!recordChanges.hasOwnProperty(recordChangeKey)) {
          continue;
        }

        const recordChange = recordChanges[recordChangeKey];
        const updatedRecord = updatedRecords.find(updatedRecord => updatedRecord.idReferenza === recordChange.data.idReferenza);

        if (updatedRecord) {
          const data = recordChange.data;

          for (const key in updatedRecord) {
            if (!updatedRecord.hasOwnProperty(key) || !data.hasOwnProperty(key)) {
              continue;
            }

            data[key] = updatedRecord[key];
          }

          if (Array.isArray(data.errors) && data.errors.length > 0) {
            changedRowIds.push(recordChangeKey);
          } else {
            delete updatedRecordChanges[recordChangeKey];
          }

          updates.push(data);
        } else {
          delete updatedRecordChanges[recordChangeKey];
        }
      }
      this.agTable.current.state.gridApi.applyTransaction({
        update: updates
      });
      this.agTable.current.state.gridApi.redrawRows(Object.keys(recordChanges).map(key => recordChanges[key]));
      this.agTable.current.cleanChangedRowIds(changedRowIds);
      this.setState({
        recordChanges: updatedRecordChanges,
        changedRecords: updatedChangedRecords
      });
    }, handleServerError);
  }

  sendNotification = () => {
    const { changedRecords } = this.state;
    const { dossier } = this.props;

    api.dossiers.sendNotification(dossier.id, {
      prodottiSimbolo: [...changedRecords],
      reason: 'notify-ps-changes'
    }).then(response => {
      this.setState({ changedRecords: [] });
      handleServerSuccess();
    }).catch(error => {
      handleServerError(error);
    });
  }

  validateProduct = (change, returnResult = false) => {
    const errors = [];
    const { dossier, user } = this.props;
    const { changeLimit } = dossier;
    const { node } = change;

    const today = moment().startOf('day');
    const dataInizioEvento = moment(node.data.dataInizioEvento).startOf('day');
    const dataFineEvento = moment(node.data.dataFineEvento).startOf('day');

    if (dataFineEvento.isSameOrBefore(dataInizioEvento)) {
      errors.push(DATA_FINE_EVENTO_NON_VALIDA);
    }

    if (dossier.idStore !== 0 && node.data.stock < node.data.stockSede && !node.data.nomeReparto.startsWith('01')) {
      errors.push(STOCK_LT_STOCK_SEDE);
    }

    if (returnResult) {
      return errors;
    }

    node.setDataValue('errors', errors);
  };

  exportDossier = () => {
    const { dossier } = this.props;
    const { visibleColumns } = this.state;
    api.dossiers.exportPS(dossier.id, visibleColumns)
      .then(response => feedbackNotification('info', 'Operazione eseguita', 'Attenzione! Il file excel con le referenze del dossier sarà disponibile tra poco e verrà inviato via email al tuo indirizzo.'))
      .catch(handleServerError);
  };

  render() {
    const { fullscreen, selectedNodes, recordChanges, canSave, columns, changedRecords } = this.state;
    const { dossier, onAddProducts, user } = this.props;
    let extraStyles = {};

    if (fullscreen) {
      extraStyles = {
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: '#ffffff',
        top: 0,
        left: 0,
        padding: '25px 25px 0 25px',
        zIndex: 999
      };
    }

    return (
      <Wrapper style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', ...extraStyles }}>
        <ActionBar>
          <Row gutter={[16, 16]}>
            <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
              <Popconfirm
                title="Le modifiche non salvate andranno perse"
                onConfirm={onAddProducts}
                okText="OK"
                cancelText="Annulla"
                disabled={Object.keys(recordChanges).length === 0}>
                {dossier.idStore === 0 && AppAccess.isAuthorized('add_prodotto_simbolo') && <Button type="primary" icon="file-add" onClick={Object.keys(recordChanges).length === 0 ? onAddProducts : null}>Aggiungi</Button>}
              </Popconfirm>
              <Button icon="save" disabled={Object.keys(this.state.recordChanges).length === 0 || !canSave} onClick={this.saveRecords} >Salva</Button>
              {[ROLE_AZ_COMM, ROLE_CPXS].includes(user.role) && <Popconfirm
                title="Sei sicuro di voler eliminare le referenze selezionate?"
                onConfirm={this.deleteRecords}
                okText="Sì"
                cancelText="No"
                disabled={selectedNodes.length === 0}>
                <Button icon="delete" disabled={true}>Elimina</Button>
              </Popconfirm>}
            </Col>
            <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
              {[ROLE_AZ_COMM, ROLE_CPXS].includes(user.role) && <Button icon="bell" onClick={this.sendNotification} disabled={changedRecords.length === 0}>Invia Notifica</Button>}
              <Button icon={fullscreen ? 'fullscreen-exit' : 'fullscreen'} onClick={() => this.setState({fullscreen: !fullscreen})} />
              <Button icon="download" onClick={this.exportDossier}>Scarica</Button>
            </Col>
          </Row>
        </ActionBar>
        <Row style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', }}>
          <Col style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <AgTable
              canMakeSelection={true}
              getRowStyle={params => {
                const { dossier } = this.props;
                const today = moment().startOf('day');

                if (dossier.idStore > 0 && params.node.data.dataFineEvento && moment(params.node.data.dataFineEvento).startOf('day').isSameOrBefore(today)) {
                  return {
                    backgroundColor: 'rgba(0, 0, 0, 0.025)'
                  };
                }

                return {};
              }}
              ref={this.agTable}
              columns={columns}
              emitChange={(change) => this.onAgTableChange(change)}
              getIncompleteProductWarnings={this.getIncompleteProductWarnings}
            />
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

export default ProdottiSimboloTable;
